// extracted by mini-css-extract-plugin
export var container = "list-module--container--qIA+g";
export var partnerList__content = "list-module--partnerList__content--Zc3SD";
export var partnerList__description = "list-module--partnerList__description--ao7er";
export var subPartnersList__alphabetical = "list-module--subPartnersList__alphabetical--fvtwk";
export var subPartnersList__alphabeticalContainer = "list-module--subPartnersList__alphabeticalContainer--C2KWb";
export var subPartnersList__alphabeticalList = "list-module--subPartnersList__alphabeticalList--glpi-";
export var subPartnersList__box = "list-module--subPartnersList__box--BJf3V";
export var subPartnersList__boxContainer = "list-module--subPartnersList__boxContainer--CfeJM";
export var subPartnersList__boxContainer__lg = "list-module--subPartnersList__boxContainer__lg---X5eB";
export var subPartnersList__boxContainer__md = "list-module--subPartnersList__boxContainer__md--EKd5S";
export var subPartnersList__boxContainer__sm = "list-module--subPartnersList__boxContainer__sm--M0k4O";
export var subPartnersList__boxContainer__xl = "list-module--subPartnersList__boxContainer__xl--5TsPy";