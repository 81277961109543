import { useStaticQuery, graphql } from 'gatsby';

const useArenaInfoWomenPage = () => {
    const { sanityPageArenaInfoWomen: PageArenaInfoWomen } = useStaticQuery(graphql`
        query {
            sanityPageArenaInfoWomen {
                title {
                    en
                    se
                } 
                _rawContent
            }
        }
    `);

    return PageArenaInfoWomen;
};

export default useArenaInfoWomenPage;
