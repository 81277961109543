import React from 'react';

import CustomLink from 'components/utils/CustomLink';

const ArenaMenu = ({ activeArena }) => (
    <ul className="tabs tabs--NoScrollDesk">
        <li>
            <CustomLink
                namedRoute="arenaInfoMen"
                className={`${activeArena === 'arenaInfoMen' ? 'active' : 'inactive'}`}
            >
                Tele2 Arena
            </CustomLink>
        </li>
        <li>
            <CustomLink
                namedRoute="arenaInfoWomen"
                className={`${activeArena === 'arenaInfoWomen' ? 'active' : 'inactive'}`}
            >
                Hammarby IP
            </CustomLink>
        </li>
    </ul>
);

export default ArenaMenu;
